var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{attrs:{"loading":_vm.firstLoad,"transition":_vm.transition,"tile":true,"type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.totalEntities,"loading":_vm.loading,"search":_vm.search,"item-key":"entityId","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.entityName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[(_vm.schema.schemaType == 'ASSET' && _vm.GetResourceType(item.versions[0]) == 'IMG')?_c('v-col',{attrs:{"cols":"auto","cover":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{attrs:{"src":_vm.GetResource(item.versions[0]),"height":"75","width":"75"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('a',{attrs:{"href":_vm.GetResource(item.versions[0]),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]):_vm._e()],1)],1)]}}],null,true)})],1):_vm._e(),_c('v-col',[(_vm.schema.schemaType == 'CONTENT' || _vm.GetResource(item.versions[0]) == null )?_c('span',[_vm._v(_vm._s(item.entityName))]):_vm._e(),(_vm.schema.schemaType == 'ASSET' && _vm.GetResourceType(item.versions[0]) == 'DOC')?_c('a',{attrs:{"href":_vm.GetResource(item.versions[0])}},[_vm._v(_vm._s(item.entityName))]):_vm._e(),(_vm.GetResourceType(item.versions[0]) == 'IMG')?_c('span',[_vm._v(_vm._s(item.entityName))]):_vm._e()])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.schema.name))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"change":_vm.getDataFromApiDebounced,"input":_vm.getDataFromApiDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("You can use % as a wildcard")])]),_c('v-spacer'),(_vm.schema.editingType == 'MULTI' || (_vm.schema.editingType == 'SINGLE' && _vm.entities.length < 1))?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.newItem}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.addNew')))],1):_vm._e(),(_vm.schema.schemaType == 'ASSET' && _vm.schema.editingType == 'MULTI')?_c('v-btn',{attrs:{"color":"secondary ml-4"},on:{"click":_vm.massiveUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.massiveUpload')))],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.form_loaded)?_c('entity-edit-form',{attrs:{"schema":_vm.schema,"is-insert":_vm.isInsert},on:{"closed":_vm.onDialogClosed,"saved":_vm.onSaved},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","retain-focus":false},model:{value:(_vm.assetsMassiveUploadsDialog),callback:function ($$v) {_vm.assetsMassiveUploadsDialog=$$v},expression:"assetsMassiveUploadsDialog"}},[_c('v-card',[(_vm.form_loaded)?_c('assets-massive-uploads',{attrs:{"schema":_vm.schema},on:{"closed":_vm.onDialogClosed,"saved":_vm.onSaved}}):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.position",fn:function(ref){
var item = ref.item;
return [(!(_vm.canReorder && item.position > 1))?_c('v-icon',{attrs:{"medium":"","color":"red"}},[_vm._v("mdi-cancel")]):_vm._e(),(_vm.canReorder && item.position > 1)?_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.moveUpFastItem(item)}}},[_vm._v("mdi-chevron-double-up")]):_vm._e(),(!(_vm.canReorder && item.position > 1))?_c('v-icon',{attrs:{"medium":"","color":"red"}},[_vm._v("mdi-cancel")]):_vm._e(),(_vm.canReorder && item.position > 1)?_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.moveUpItem(item)}}},[_vm._v("mdi-arrow-up")]):_vm._e(),(_vm.canReorder)?_c('v-chip',{attrs:{"x-small":"","chip":""}},[_vm._v(" "+_vm._s(item.position)+" ")]):_vm._e(),(!(_vm.canReorder && (item.rowIndex + 1) < _vm.totalEntities))?_c('v-icon',{attrs:{"medium":"","color":"red"}},[_vm._v("mdi-cancel")]):_vm._e(),(_vm.canReorder && (item.rowIndex + 1) < _vm.totalEntities )?_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.moveDownItem(item)}}},[_vm._v("mdi-arrow-down")]):_vm._e(),(!(_vm.canReorder && (item.rowIndex + 1) < _vm.totalEntities))?_c('v-icon',{attrs:{"medium":"","color":"red"}},[_vm._v("mdi-cancel")]):_vm._e(),(_vm.canReorder && (item.rowIndex + 1) < _vm.totalEntities )?_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.moveDownFastItem(item)}}},[_vm._v("mdi-chevron-double-down")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    
    <v-skeleton-loader :loading="firstLoad" :transition="transition" :tile="true" type="table">
      
      <v-data-table
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="totalEntities"
        :loading="loading"
        :search="search"
        item-key="entityId"
        dense
      >

        <template v-slot:[`item.entityName`]="{ item }">

          <v-row align="center">
            <v-col cols="auto" cover v-if="schema.schemaType == 'ASSET' && GetResourceType(item.versions[0]) == 'IMG'">
              <v-hover>
    <template v-slot:default="{ hover }">
      <v-img :src="GetResource(item.versions[0])" height="75" width="75">
         
        <v-fade-transition>
          <v-overlay
            v-if="hover"
            absolute
            color="#036358"
          >
             <a :href="GetResource(item.versions[0])" target="_blank"><v-icon>mdi-cloud-download</v-icon></a>
           
          </v-overlay>
        </v-fade-transition>
        </v-img>

        
        
    
    </template>
  </v-hover>
              </v-col>
            <v-col>
              <!-- ENTITY -->
              <span v-if="schema.schemaType == 'CONTENT' || GetResource(item.versions[0]) == null ">{{item.entityName}}</span>

              <!-- DOCUMENTI -->
              <a :href="GetResource(item.versions[0])" v-if="schema.schemaType == 'ASSET' && GetResourceType(item.versions[0]) == 'DOC'">{{item.entityName}}</a>

              <!-- IMMAGINI -->
              <span v-if="GetResourceType(item.versions[0]) == 'IMG'">{{item.entityName}}</span>
            </v-col>
          </v-row>

        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{new Date(item.createdAt).toLocaleString()}}</span>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          <span>{{new Date(item.updatedAt).toLocaleString()}}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{schema.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="getDataFromApiDebounced"
              @input="getDataFromApiDebounced"
            ></v-text-field>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>You can use % as a wildcard</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="newItem"
              v-if="schema.editingType == 'MULTI' || (schema.editingType == 'SINGLE' && entities.length < 1)"
            >
              <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $vuetify.lang.t('$vuetify.addNew') }}</v-btn>

            <v-btn
              color="secondary ml-4"
              @click="massiveUpload"
              v-if="schema.schemaType == 'ASSET' && schema.editingType == 'MULTI'"
            >
              <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $vuetify.lang.t('$vuetify.massiveUpload') }}</v-btn>

            <v-dialog
              v-model="dialog"              
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              :retain-focus="false"
            >
              
              <v-card>
              <entity-edit-form
                v-if="form_loaded"
                v-model="editedItem"
                :schema="schema"
                :is-insert="isInsert"
                @closed="onDialogClosed"
                @saved="onSaved"
                
              ></entity-edit-form>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="assetsMassiveUploadsDialog"              
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              :retain-focus="false"
            >
              
              <v-card>
              <assets-massive-uploads
                v-if="form_loaded"
                :schema="schema"
                @closed="onDialogClosed"
                @saved="onSaved"
                
              ></assets-massive-uploads>
              </v-card>
            </v-dialog>
            
          </v-toolbar>
        </template>
        <template v-slot:[`item.position`]="{ item }">

         
          <v-icon medium v-if="!(canReorder && item.position > 1)" color="red">mdi-cancel</v-icon>
          <v-icon medium @click="moveUpFastItem(item)" v-if="canReorder && item.position > 1">mdi-chevron-double-up</v-icon>
          
            
          
          
          <v-icon medium v-if="!(canReorder && item.position > 1)" color="red">mdi-cancel</v-icon>
          <v-icon medium @click="moveUpItem(item)" v-if="canReorder && item.position > 1">mdi-arrow-up</v-icon>
          
          
          <v-chip v-if="canReorder" x-small chip>
            {{item.position}}
          </v-chip>
          
          <v-icon medium v-if="!(canReorder && (item.rowIndex + 1) < totalEntities)" color="red">mdi-cancel</v-icon>          
          <v-icon medium @click="moveDownItem(item)" v-if="canReorder && (item.rowIndex + 1) < totalEntities ">mdi-arrow-down</v-icon>

          <v-icon medium v-if="!(canReorder && (item.rowIndex + 1) < totalEntities)" color="red">mdi-cancel</v-icon>          
          <v-icon medium @click="moveDownFastItem(item)" v-if="canReorder && (item.rowIndex + 1) < totalEntities ">mdi-chevron-double-down</v-icon>

          
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          
          
          <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </div>
</template>


<script>
import { baseApiUrl,apiCall } from "../utils/api";
//import PrettyPrint from "../components/pretty-print";
import EntityEditForm from "../components/EntityEditForm";
import _ from 'lodash';
import AssetsMassiveUploadsVue from '../components/AssetsMassiveUploads.vue';

export default {
  name: "Entities",
  props: {
    schemaKey: {
      type: String,
      default: ""
    }
  },
  components: {
    //"pretty-print": PrettyPrint,
    "entity-edit-form":EntityEditForm,
    "assets-massive-uploads": AssetsMassiveUploadsVue,
  },
  data() {
    return {
      tab: null,
      blockModal: false,
      dialog: false,
      assetsMassiveUploadsDialog:false,
      totalEntities: 0,
      schema: {},
      entities: [],
      firstLoad: true,
      loading: true,
      form_loaded: false,
      transition: "scale-transition",
      options: {
        page : 1,
        itemsPerPage : 10,
        sortBy: ["createdAt"],
         sortDesc: [true]
      },
      editedItem: {},
      defaultItem: {
        versions: [{
          contents: []
        }]
      },  
      canReorder: false,    
      editedIndex: -1,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.description'),
          align: "start",
          sortable: true,
          value: "entityName"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.createdAt'),
          align: "start",
          sortable: true,
          value: "createdAt"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.updatedAt'),
          align: "start",
          sortable: true,
          value: "updatedAt"
        },
        { text: this.$vuetify.lang.t('$vuetify.actions'), value: "actions", sortable: false }
      ],
      state: {},
      isInsert: false,
      search: ''
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApiDebounced();
      },
      deep: true,
      immediate: false
    }
  },
  computed: {
    // a computed getter
    schemaConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configuration);
    },
    schemaLocalizationConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configurationLocalization);
    },
    /*isInsert: function() {
      return this.editedIndex == -1;
    },*/
    isUpdate: function() {
      return !this.isInsert;// this.editedIndex > -1;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    }/*,
    canReorder() {
      return this.schemaConfiguration?.dataTable?.canReorder;
    },*/
  },
  mounted() {
    this.getDataFromApiDebounced();
  },
  beforeUpdate() {
    
  },
  methods: {
    onChangeState(value) {
      this.state = value;
    },
    getDataFromApi() {
      

      return new Promise((resolve, reject) => {
        

        this.loading = true;

        apiCall({
          url: "Schema/byKey/" + this.schemaKey,
          data: {},
          method: "GET"
        })
          .then(resp => {
            this.schema = resp;

            this.canReorder = this.schemaConfiguration?.dataTable?.canReorder || false;
            
            
            if(this.canReorder && this.headers.length < 5)
            {

              this.headers.splice(3, 0, {
                  text: this.$vuetify.lang.t('$vuetify.position'),
                  align: "start",
                  sortable: true,
                  value: "position"
                });
              

              this.options.sortBy = ["position"];
              this.options.sortDesc =  [false];
            }
            else if(!this.canReorder && this.headers.length > 4)
            {
              this.headers.splice(3,1);
              this.options.sortBy = ["createdAt"];
              this.options.sortDesc =  [true];
            }

            this.defaultItem.versions[0].contents = [];
            this.defaultItem.versions[0].contents.push({
              LanguageId: "it",
              Content: resp.model
            });

            this.firstLoad = false;
            /*console.log(
              "schema key",
              this.schemaKey,
              "schema",
              this.schema,
              "configuration",
              this.schemaConfiguration
            );*/

            const {
                  sortBy, sortDesc,
                  page,
                  itemsPerPage
                } = this.options;

            let filters = {
              WebSiteId: this.currentWebSite,
              SchemaId: this.schema.schemaId,
              Page: page,
              Limit: itemsPerPage,
              SortBy: Array.isArray(sortBy) ? sortBy : [...sortBy],
              SortDesc: Array.isArray(sortDesc) ? sortDesc : [...sortDesc],
              GenericSearch: '%'+this.search+'%'
            };
            apiCall({ url: "Entities/paged", data: filters, method: "GET" })
              .then(resp => {
                
                //console.log("Paged Results Response",resp);
                let items = resp.results;
                const total = resp.rowCount;

                /*if (itemsPerPage > 0) {
                  items = items.slice(
                    (page - 1) * itemsPerPage,
                    page * itemsPerPage
                  );
                }*/

                items.forEach((entity,index) => {
                  entity.versions[0]["contentDecoded"] = JSON.parse(entity.versions[0].content) || {};
                  entity.rowIndex = index;
                  entity.versions[0].contents.forEach(content => {
                    content["contentDecoded"] =
                      JSON.parse(content.content) || {};
                  });
                });

                //console.log("items", items);

                this.loading = false;
                resolve({
                  items,
                  total
                });
              })
              .catch(err => {
                reject(err);
              });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getEmptyDataFromApi(webSiteId, schemaId) {
      return new Promise((resolve, reject) => {
        apiCall({
          url:
            "Entities/empty?webSiteId=" + webSiteId + "&schemaId=" + schemaId,
          data: {},
          method: "GET"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    editItem(item) {
      this.form_loaded = false;
      //console.log(item);
      this.editedIndex = this.entities.indexOf(item);
      this.isInsert = false;

      //console.log("this.editedIndex",this.editedIndex,"this.isUpdate",this.isUpdate);

      apiCall({ url: "Entities/" + item.entityId, data: {}, method: "GET" })
        .then(resp => {
          this.entities[this.editedIndex] = resp;

          item = this.entities[this.editedIndex];

          item.versions[0]["contentDecoded"] =
            JSON.parse(item.versions[0].content) || this.schemaConfiguration.model;
          item.versions[0].contents.forEach(content => {
            content["contentDecoded"] = JSON.parse(content.content) || {};
            //console.log(content);
          });

          this.editedItem = item;
          this.form_loaded = true;
          this.dialog = true;
        })
        .catch(err => {});
    },
    newItem() {
      this.form_loaded = false;
      this.editedIndex = -1;
      this.isInsert = true;

      let webSiteId = this.$store.getters.getCurrentWebSite;
      this.getEmptyDataFromApi(webSiteId, this.schema.schemaId)
        //apiCall({ url: "Entities/empty?webSiteId="+webSiteId+"&schemaId="+this.schema.schemaId, data: {}, method: "GET" })
        .then(resp => {
          //console.log("resp",resp);
          this.editedItem = resp;
          this.form_loaded = true;
          this.dialog = true;
        })
        .catch(err => {});
    },

    deleteItem(item) {
      this.$confirm("Cancellare l'elemento corrente?").then(res => {
        //console.log(res);
        if (res) {
          this.blockModal = true;
          apiCall({
            url: "Entities/" + item.entityId,
            data: {},
            method: "DELETE"
          })
            .then(resp => {
              this.editedItem = -1;

              this.getDataFromApi().then(data => {
                this.entities = data.items;
                this.totalEntities = data.total;
                this.blockModal = false;
              });
            })
            .catch(err => {
              this.blockModal = false;
            });
        }
      });
    },
    moveItem(item,newPosition){
      this.blockModal = true;

      let formData = new FormData();
      //Il nome del campo deve corrispondere al parametro presente nelle api
      formData.append('newPosition', newPosition);

      apiCall({
            url: "Entities/ChangeEntityPosition/" + item.entityId,
            data: formData,
            method: "POST"
          })
            .then(resp => {
              this.editedItem = -1;

              this.getDataFromApi().then(data => {
                this.entities = data.items;
                this.totalEntities = data.total;
                this.blockModal = false;
              });
            })
            .catch(err => {
              this.blockModal = false;
            });
    },
    moveUpItem(item){
      this.moveItem(item,item.position - 1);
    },
    moveDownItem(item){
      this.moveItem(item,item.position + 1);
    },
    moveUpFastItem(item){
      this.moveItem(item,item.position - 10);
    },
    moveDownFastItem(item){
      this.moveItem(item,item.position + 10);
    },

    onDialogClosed() {
      this.dialog = false;
      this.assetsMassiveUploadsDialog = false;
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;

        this.getDataFromApi().then(data => {
          this.entities = data.items;
          this.totalEntities = data.total;
          this.blockModal = false;
        });
      });
    },

    onSaved() {
      //console.log(this.editedItem);
      this.onDialogClosed();
      /*this.getDataFromApi().then(data => {
        this.entities = data.items;
        this.totalEntities = data.total;
        this.onDialogClosed();
        this.blockModal = false;
      });*/
    },
    GetResource(item){

      if(JSON.parse(item.content).file)
      {
         return baseApiUrl+'WebSites/downloadFile/'+this.currentWebSite+'?filename='+JSON.parse(item.content).file.fileName;
      }

    },
    GetResourceType(item){

      if(JSON.parse(item.content).file)
      {

        if(JSON.parse(item.content).file.contentType.match("image"))
        {
           return 'IMG';
        }
        else
        {  
           return 'DOC'
        }

      }

    },
    getDataFromApiDebounced: _.debounce(function() {
          this.getDataFromApi().then(data => {
            this.entities = data.items;
            this.totalEntities = data.total;
          }).catch(e => {
            console.error(e);
          });   
      }, 1000),
          
    
    massiveUpload() {
      this.form_loaded = true;
      
      this.editedIndex = -1;
      this.isInsert = true;
      this.assetsMassiveUploadsDialog = true;

      
      
    }
  }
};
</script>